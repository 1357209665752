import {AppBar, Box, Button, CircularProgress, Toolbar, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup} from "firebase/auth";
import {useNavigate} from "react-router-dom";

export const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false);
      if (user) {
        navigate("/main-menu");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async () => {
    signInWithPopup(getAuth(), new GoogleAuthProvider())
      .then((userCredential) => {
        console.info(`Succeed to login. user=${userCredential.user}`)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(`Failed to login: Code=${errorCode}, msg=${errorMessage}`)
      });
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Login Page
          </Typography>
        </Toolbar>
      </AppBar>
      <Button disabled={loading} variant="contained" color="primary" onClick={handleLogin}>
        Login with Google
      </Button>

      <Box sx={{ position: "relative", height: "100vh" }}>
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {/* Render login page UI */}
      </Box>
    </div>
  );
};