import {AppBar, Toolbar, Typography} from "@mui/material";
import React from "react";

export const Setting = () => {
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Setting
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};