import {Box, IconButton, Typography, useTheme} from "@mui/material";
import {ArrowBackIos, ArrowForwardIos, Settings} from "@mui/icons-material";
import React from "react";
import {Pagination} from "../Client";

type ControllerProps = {
  setOptionModal: (isShowing: boolean) => void;
  handlePrevPage: () => void;
  handleNextPage: () => void;
  pagination: Pagination;
}

export function Controller(
  {
    setOptionModal,
    handlePrevPage,
    handleNextPage,
    pagination,
  }: ControllerProps) {
  const theme = useTheme();
  return (
    <Box position="fixed" bottom={0} left={0} width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" p={2}
           bgcolor={theme.palette.background.default} boxShadow={1}>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <IconButton onClick={() => setOptionModal(true)}>
            <Settings />
          </IconButton>
        </Box>

        <Box display="flex" alignItems="center">
          <IconButton onClick={handlePrevPage} disabled={pagination.currentPage === 1}>
            <ArrowBackIos />
          </IconButton>
          <Typography variant="caption" component="p">
            {`페이지 ${pagination.currentPage} / ${pagination.totalPages}`}
          </Typography>
          <IconButton onClick={handleNextPage} disabled={pagination.currentPage === pagination.totalPages}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}