import {Box, Button, Card as MUICard, CardContent, Typography,} from "@mui/material";
import {Japan} from "../Client";
import {useEffect, useState} from "react";

type JapanCardProps = {
  word: Japan;
  onCardComplete: (jReadDone: boolean, hReadDone?: boolean, hWriteDone?: boolean) => void;
};
const JapanCard = ({ word, onCardComplete }: JapanCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => setIsExpanded(false), [word])

  const handleToggle = () => setIsExpanded(!isExpanded);

  return (
    <MUICard sx={{ minWidth: 275, marginBottom: 1 }} key={word._id}>
      <CardContent>
        <Typography alignSelf={"flex-end"} variant="caption">
          {isExpanded ? '▲' : '▼'} N{word.jlptLvl} | {word.wordClasses?.join("|")}
        </Typography>
        {/*첫째 줄*/}
        <Box onClick={handleToggle}
             display='flex'
             flexDirection='row'
             mt={1}
             justifyContent="space-between">

          {/* 일어단어 */}
          <Box display='flex' flexDirection='column' minWidth={100}>
            {
              word.hanja
                ?
                <>
                  <Typography variant="h4">{word.hanja}</Typography>
                  <Typography variant="h6" color="text.secondary" mt={2}>{word.showWord}</Typography>
                </>
                :
                <Typography variant="h5">{word.showWord}</Typography>
            }

          </Box>

          {/* 해석 */}
          <Box display='flex' flexDirection='column' alignItems="flex-end">
            {word.means.map((mean, idx) =>
              <Typography key={idx} mt={1}> {mean} </Typography>
            )}
          </Box>
        </Box>

        {/* 한자들 */}
        <Box display='flex' flexDirection={word.hanjaKo.length < 4 ? 'row' : 'column'} mt={2}>
          {word.hanjaKo.map((hanja, idx) =>
            <Typography
              key={`hanja${idx}`}
              variant={"subtitle1"}
              color="text.secondary"
              mr={1}>
              {hanja.hanja} {hanja.mean.split(",")[0]},
            </Typography>
          )}
        </Box>

        <Box display='flex' flexDirection='row' justifyContent="flex-end" alignItems='center' mt={2}>
          <Button onClick={() => onCardComplete(true, true, true)}>
            한자쓰기
          </Button>

          <Button onClick={() => onCardComplete(true, true)}>
            한자읽기
          </Button>

          <Button onClick={() => onCardComplete(true)}>
            일어읽기
          </Button>
        </Box>

        {
          isExpanded &&
            <Box mt={1}>

            </Box>
        }
      </CardContent>
    </MUICard>
  );
}

export default JapanCard;
