import React from "react";
import {AppBar, Box, Button, Toolbar, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import {getAuth} from "firebase/auth";

export const MainMenu = () => {
  function logout() {
    getAuth().signOut();
  }

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Main Menu
          </Typography>
        </Toolbar>
      </AppBar>
      <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
        <Button variant="contained" sx={{ marginBottom: "10px" }}>
          <NavLink to="/hanja" style={{ textDecoration: "none", color: "inherit" }}>한자 외우기</NavLink>
        </Button>
        <Button variant="contained" sx={{ marginBottom: "10px" }}>
          <NavLink to="/japan" style={{ textDecoration: "none", color: "inherit" }}>일본어 외우기</NavLink>
        </Button>
      </Box>
    </Box>
  );
};
