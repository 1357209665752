import React from 'react';
import {Box, Checkbox, FormControl, FormControlLabel, Grid, IconButton, Modal, Typography} from '@mui/material';
import {Close} from '@mui/icons-material';

type Props = {
  open: boolean;
  handleClose: () => void;
  jpLvlChecked: boolean[];
  setJpLvlChecked: (check: boolean[]) => void;
};

const HanjaOptionModal: React.FC<Props> = (
  {
    open,
    handleClose,
    jpLvlChecked,
    setJpLvlChecked,
  }) => {
  const jpLvlList = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];

  const handleJpLvlCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedLevel = Number(event.target.value);
    const isChecked = event.target.checked;
    const newValue = [...jpLvlChecked];
    newValue[checkedLevel] = isChecked;
    setJpLvlChecked(newValue);
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        margin: 'auto'
      }}
      open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          outline: 'none',
        }}>

        <Box
          display="flex"
          flexDirection='row'
          justifyContent="space-between"
          alignItems="center">
          <Typography>필터 옵션</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <FormControl>
          <Typography variant="subtitle2">일본급수</Typography>
          <Grid>
            {jpLvlList.map((jp) => (
              <FormControlLabel
                key={jp}
                control={<Checkbox
                  checked={jpLvlChecked[jp - 1]}
                  onChange={handleJpLvlCheckboxChange}
                  value={jp - 1}
                />}
                label={jp}
              />
            ))}
          </Grid>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default HanjaOptionModal;
