import {Box, Button, Card as MUICard, CardContent, Typography,} from "@mui/material";
import {Hanja} from "../Client";
import {useEffect, useState} from "react";

type HanjaCardProps = {
  hanja: Hanja;
  onCardComplete: () => void;
};

function isChinese(str: string): boolean {
  return /^[\u4E00-\u9FFF]+$/.test(str);
}

const HanjaCard = ({ hanja, onCardComplete }: HanjaCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(false)
  }, [hanja])

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleComplete = () => {
    onCardComplete();
  };

  // 레벨 태그
  const levelTags = []
  if (hanja.jpLvl) {
    levelTags.push(`JP-${hanja.jpLvl}`)
  }
  if (hanja.jlptLvl) {
    levelTags.push(`N${hanja.jlptLvl}`)
  }
  if (hanja.krLvl) {
    levelTags.push(`KR-${hanja.krLvl}`)
  }

  let krHanja
  if (hanja.krHanja) {
    const krHanjaStrip = hanja.krHanja.replace(/<[^>]*>/g, '');
    if (krHanjaStrip !== hanja.hanja) {
      krHanja = krHanjaStrip
    }
  }

  return (
    <MUICard sx={{ minWidth: 275, marginBottom: 1 }}>
      <CardContent>
        <Typography alignSelf={"flex-end"} variant="caption"> {levelTags.join(" | ")}</Typography>
        {/*첫째 줄*/}
        <Box onClick={handleToggle}
             display='flex'
             flexDirection='row'
             mt={1}>
          <Box display='flex' flex={1} flexDirection='row' alignItems='center'>
            {/*한자*/}
            <Typography ml={2} mt={2} variant="h2">{hanja.hanja}</Typography>
            {/*한국한자*/}
            <Typography ml={1} mt={2} variant="h4" color="text.secondary">
              {krHanja}
            </Typography>
          </Box>

          <Box display='flex' flex={1} flexDirection='column'>
            {/*한국어 음훈, 일어 음독*/}
            <Typography dangerouslySetInnerHTML={{ __html: hanja.mean + ` (${hanja.jpRead || '-'})` }} />

            {/*일어 훈독*/}
            <Typography color="text.secondary" mb={3}>
              {hanja.jpMean?.replaceAll("·", ", ") || '-'}
            </Typography>

            {/* 예제단어 */}
            {hanja.jpMeanEx
              ?.filter(ex => isChinese(ex.hanja)
                && ex.hanja.length >= 2
                && ex.means[0].length === ex.hanja.length
              )
              .map(ex => `${ex.hanja} (${ex.word}, ${ex.means[0]})`)
              .splice(0, 3)
              .map(ex => <Typography color="text.secondary">- {ex}</Typography>)
            }
          </Box>
        </Box>

        <Box display='flex' flexDirection='row' justifyContent="space-between">
          <Typography>{isExpanded ? '▲' : '▼'} {hanja.jpMeanEx?.length || '-'}</Typography>
          <Button onClick={handleComplete} sx={{ mt: 1, float: "right" }}>
            읽기 가능
          </Button>
        </Box>

        {
          isExpanded &&
            <Box mt={1}>
              {/*부수. html 까고*/}
                <Typography color="text.secondary" mb={2}>
                  {"[부수] "}
                  {hanja.radical.replace(/<[^>]*>/g, '')}
                  {hanja.radicalMean}
                </Typography>

              {/* 뜻 */}
              {hanja.meanings.map((meaning, index) => (
                <Typography
                  key={index}
                  color="text.secondary"
                  dangerouslySetInnerHTML={{ __html: `${index + 1}. ${meaning}` }} />
              ))}

                <Typography mt={3}>[일본단어]</Typography>

              {/*일본어 예제*/}
              {hanja.jpMeanEx?.map((jp, index) => (
                <Typography
                  key={index}
                  color="text.secondary"
                  dangerouslySetInnerHTML={{ __html: `N${jp.jlptLvl}. ${jp.hanja} (${jp.word}) ${jp.means}` }} />
              ))}
            </Box>
        }
      </CardContent>
    </MUICard>
  );
};

export default HanjaCard;
