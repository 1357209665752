import axios from 'axios';
import {Box, IconButton, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import HanjaCard from "../components/HanjaCard";
import {Hanja, Pagination} from "../Client";
import {ArrowBackIos, ArrowForwardIos, Settings} from "@mui/icons-material";
import HanjaListOption from "../components/HanjaListOption";
import {getAuth} from "firebase/auth";
import {apiHost} from "../Environments";

export const HanjaList = () => {
  const theme = useTheme();

  // 서버 응답정보. 단어와 페이지
  const [hanjaWords, setHanjaWords] = useState<Hanja[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1
  });

  // Filter Options
  const [readDone, setReadDone] = useState<boolean>(false)
  const [writeDone, setWriteDone] = useState<boolean>(false)
  const [jpLvlChecked, setJpLvlChecked] = useState<boolean[]>(Array(10).fill(true));

  // 페이징
  const [page, setPage] = useState<number>(1); // 현재 페이지

  // Modal
  const [openOptionModal, setOptionModal] = useState<boolean>(false)

  async function fetchHanjaWords() {
    const checkJpLvls = jpLvlChecked.reduce((acc: number[], cur, index) => {
      if (cur) {
        acc.push(index + 1);
      }
      return acc;
    }, []);

    const response = await axios.get(`${apiHost}/hanja?limit=10`, {
      params: {
        jpLvls: checkJpLvls.join(","),
        page,
        read: readDone,
        write: writeDone,
      },
      headers: {
        'user-id': getAuth().currentUser?.uid,
      },
    });
    const data = await response.data;
    setHanjaWords(data.data);
    setPagination(data.pagination);
  }

  async function updateHanjaReadDone(hanjaId: string) {
    try {
      await axios.put(`${apiHost}/hanja/${hanjaId}`, null, {
        params: {
          readDone: true,
        },
        headers: {
          'user-id': getAuth().currentUser?.uid,
        },
      });

      fetchHanjaWords()
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchHanjaWords()
  }, [jpLvlChecked, page, readDone, writeDone]);

  const handlePrevPage = () => {
    setPage(page - 1);
    window.scrollTo({ top: 0 });
  };

  const handleNextPage = () => {
    setPage(page + 1);
    window.scrollTo({ top: 0 });
  };

  return (
    <Box position="relative" minHeight="100vh">
      <Box display={"flex"} justifyContent='center' p={2}>
        <Typography variant={"h5"}>{hanjaWords.map((hanja, idx) => hanja.hanja).join(", ")}</Typography>
      </Box>
      <Box p={2} pb={10} pt={1}>
        {hanjaWords.map((hanja, idx) => (
          <HanjaCard
            key={idx} hanja={hanja} onCardComplete={() => {
            updateHanjaReadDone(hanja._id)
          }} />
        ))}
      </Box>

      {/* 하단 컨트롤러 */}
      <Box position="fixed" bottom={0} left={0} width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" p={2}
             bgcolor={theme.palette.background.default} boxShadow={1}>

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <IconButton onClick={() => setOptionModal(true)}>
              <Settings />
            </IconButton>
          </Box>

          <Box display="flex" alignItems="center">
            <IconButton onClick={handlePrevPage} disabled={page === 1}>
              <ArrowBackIos />
            </IconButton>
            <Typography variant="caption" component="p">
              {`페이지 ${page} / ${pagination.totalPages}`}
            </Typography>
            <IconButton onClick={handleNextPage} disabled={page === pagination.totalPages}>
              <ArrowForwardIos />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <HanjaListOption
        open={openOptionModal}
        handleClose={() => setOptionModal(false)}
        jpLvlChecked={jpLvlChecked}
        setJpLvlChecked={setJpLvlChecked}
      />
    </Box>
  );
};