import axios from 'axios';
import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import HanjaListOption from "../components/HanjaListOption";
import {getAuth} from "firebase/auth";
import {apiHost} from "../Environments";
import {Controller} from "../components/Controller";
import JapanCard from "../components/JapanCard";
import {Japan, Pagination} from "../Client";

export const JapanList = () => {
  // 페이징
  const [page, setPage] = useState<number>(1); // 현재 페이지

  // 서버 응답정보. 단어와 페이지
  const [words, setWords] = useState<Japan[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1
  });

  // Filter Options
  const [jReadDone, setJReadDone] = useState<boolean>(false)
  const [hReadDone, setHReadDone] = useState<boolean>(false)
  const [hWriteDone, setHWriteDone] = useState<boolean>(false)
  const [jlptLvlChecked, setJlptLvlChecked] = useState<boolean[]>(Array(6).fill(true));

  // Modale
  const [openOptionModal, setOptionModal] = useState<boolean>(false)

  async function fetchWords() {
    const checkJlptLvls = jlptLvlChecked.reduce((acc: number[], cur, index) => {
      if (cur) {
        acc.push(index + 1);
      }
      return acc;
    }, []);

    const response = await axios.get(`${apiHost}/japan?limit=10`, {
      params: {
        jlptLvls: checkJlptLvls.join(","),
        page,
        jReadDone,
        hReadDone,
        hWriteDone,
      },
      headers: {
        'user-id': getAuth().currentUser?.uid,
      },
    });
    const data = await response.data;
    setWords(data.data);
    setPagination(data.pagination);
  }

  async function updateWord(wordId: string,
                            jReadDone: boolean = false,
                            hReadDone: boolean = false,
                            hWriteDone: boolean = false) {
    try {
      await axios.put(`${apiHost}/japan/${wordId}`, null, {
        headers: { 'user-id': getAuth().currentUser?.uid, },
        params: {
          jReadDone,
          hReadDone,
          hWriteDone,
        },
      });
      fetchWords()
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchWords()
  }, [jlptLvlChecked, page, hReadDone, hWriteDone]);

  const handlePrevPage = () => {
    setPage(page - 1);
    window.scrollTo({ top: 0 });
  };

  const handleNextPage = () => {
    setPage(page + 1);
    window.scrollTo({ top: 0 });
  };

  return (
    <Box position="relative" minHeight="100vh">
      <Box p={2} pb={10}>
        {words.map((word, idx) => (
          <JapanCard
            key={idx} word={word}
            onCardComplete={(
              jReadDone,
              hReadDone,
              hWriteDone,
            ) => {
              updateWord(word._id,
                jReadDone,
                hReadDone,
                hWriteDone,
              )
            }} />
        ))}
      </Box>

      {/* 하단 컨트롤러 */}
      <Controller
        setOptionModal={setOptionModal}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        pagination={pagination}
      />

      <HanjaListOption
        open={openOptionModal}
        handleClose={() => setOptionModal(false)}
        jpLvlChecked={jlptLvlChecked}
        setJpLvlChecked={setJlptLvlChecked}
      />
    </Box>
  );
};