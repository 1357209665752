import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Setting} from "./Setting";
import {MainMenu} from "./pages/MainMenu";
import {HanjaList} from "./pages/HanjaList";
import {LoginPage} from "./pages/LoginPage";
import {initializeApp} from "firebase/app";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from "@mui/material";
import {JapanList} from "./pages/JapanList";

const darkTheme = createTheme({
  palette: { mode: 'dark' },
});

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDCv5Fk__DgUmkVzgXWvrBoluq-dp8Um_s",
  authDomain: "my-voca-ca208.firebaseapp.com",
  projectId: "my-voca-ca208",
  storageBucket: "my-voca-ca208.appspot.com",
  messagingSenderId: "1018686999332",
  appId: "1:1018686999332:web:609ecd2fcdfb5005b701b1"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);

function App() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          {isAuthenticated ? (
            <>
              <Route path="/main-menu" element={<MainMenu />} />
              <Route path="/setting" element={<Setting />} />
              <Route path="/hanja" element={<HanjaList />} />
              <Route path="/japan" element={<JapanList />} />
            </>
          ) : (
            <Route path="*" element={<LoginPage />} />
          )}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
